import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Bar, Pie, Doughnut, Line } from 'react-chartjs-2';
import { Table, DatePicker, Tag, Modal, notification } from 'antd';

import jobRep from '../repository/jobRep';
import { sendNotify } from '../helper/notification';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function Dashbaord() {
  const [pieChartData, setPieChartData] = useState([])
  const [tableData, setTableData] = useState([])
  const [pieChartLabel, setPieChartLabel] = useState([])
  // const barOptions = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: 'top',
  //       labels: {
  //         font: {
  //             size: 14,
  //             family: 'Outfit-Regular'
  //         }
  //       }
  //     },
  //     title: {
  //       display: false,
  //     },
  //   },
  // };

  // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  // const barData = {
  //   labels,
  //   datasets: [
  //     {
  //       label: 'Dataset 1',
  //       data: labels.map(() => Math.random({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(255, 99, 132, 0.7)',
  //     },
  //     {
  //       label: 'Dataset 2',
  //       data: labels.map(() => Math.random({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(53, 162, 235, 0.7)',
  //     },
  //   ],
  // };

  const columns = [
    {
      title: 'No of Entity',
      dataIndex: 'id',
    },
    {
      title: 'Risk Level',
      dataIndex: 'status',
      render: (_, { status }) => (
        <>
          {status.map((s) => {
            let color = '';
            switch (s) {
              case 'Medium':
                color = 'gold'
                break;
              case 'Low':
                color = 'green'
                break;
              case 'High':
                color = 'red'
                break;
            }
            return (
              <Tag color={color} key={s}>{s}</Tag>
            );
          })}
        </>
      ),
    },
  ]

  // Status: RFI, In Progress, Completed 

  useEffect(() => {
    getjobs();
  }, [])

  const getjobs = () => {
    jobRep.getChartData().then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.message)
        } else {
          if (response && response?.data.data.length > 0) {
            setPieChartData(response.data.data)
            setPieChartLabel(response.data.label)
            let obj = response?.data.data.map((data, i) => {
              return {
                id: data,
                status: [response.data.label[i]],
              }
            })
            setTableData(obj)
          }
        }
      }
    })
      .catch(error => (sendNotify('error', JSON.stringify(error))));
  }

  const pieData = {
    labels: pieChartLabel,
    datasets: [
      {
        label: '# of Votes',
        data: pieChartData,
        backgroundColor: [
          'rgba(255, 99, 132, 0.7)',
          'rgba(54, 162, 235, 0.7)',
          'rgba(255, 206, 86, 0.7)',
          'rgba(75, 192, 192, 0.7)',
          'rgba(153, 102, 255, 0.7)',
          'rgba(255, 159, 64, 0.7)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <section className='mt-md-3'>
      <div className='container-fluid px-0'>
        <div className="page-header">
          <h1>Dashboard</h1>
        </div>
        <div className="row gy-4">
          {/* <div className="col-md-8">
            <div className="chart-box">
              <h2>Summary</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <Bar options={barOptions} data={barData} />
            </div>
          </div> */}
          <div className="col-md-4">
            <div className="chart-box">
              <h2 className='mb-5'>Job Risk Status</h2>
              {/* <p>Lorem ipsum dolor sit amet consectetur.</p> */}
              <Pie data={pieData} />
            </div>
          </div>
          <div className="col-md-8">
            <div className="chart-box">
              <h2 className='mb-3'>Summary</h2>
              {/* <p>Lorem ipsum dolor sit amet consectetur.</p> */}
              <Table columns={columns} dataSource={tableData} className='custom-table' loading={false} />
            </div>
          </div>
          {/* <div className="col-md-4">
            <div className="chart-box">
              <h2>Summary</h2>
              <p>Lorem ipsum dolor sit amet consectetur.</p>
              <Doughnut data={pieData} />
            </div>
          </div>
          <div className="col-md-8">
            <div className="chart-box">
              <h2>Summary</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <Line options={barOptions} data={barData} />
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}
