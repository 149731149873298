import Repository, { apiUrl } from './index';

class JobRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async getjobs() {
        let url = `${apiUrl}/jobs/getjobs`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

    async getFileFromS3(file) {
        let url = `${apiUrl}/jobs/download?id=${file}`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

    async getJobsById(id) {
        let url = `${apiUrl}/jobs/getjobs?id=${id}`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

    async assignJobs(payload) {
        let url = `${apiUrl}/jobs/assignjobs`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

    async getPeriodicList() {
        let url = `${apiUrl}/jobs/periodic_list`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

    async updatePeriodicList(payload) {
        let url = `${apiUrl}/jobs/update_list`;
        const reponse = await Repository.put(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

    async getChartData() {
        let url = `${apiUrl}/jobs/getdata`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

    async deleteJobById(payload) {
        let url = `${apiUrl}/jobs/delete_job`;
        const reponse = await Repository.put(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async jobExtraction(payload) {
        let url = `${apiUrl}/jobs/extraction`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async bulkAcknowledgeTicket(payload) {
        let url = `${apiUrl}/jobs/bulkassign`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

}

export default new JobRepository();