import React, { useEffect, useState } from 'react'
import { Image } from 'antd';

import Logo from '../assets/img/logo-with-text.png'
import NoImg from '../assets/img/no-img.jpg'

import { sendNotify } from '../helper/notification';
import authRep from '../repository/authRep';

export default function Settings() {
    const [uploadedFile, setUploadedFile] = useState('');
    const [uploadedImg, setUploadedImg] = useState();
    const [uploaded, setUploaded] = useState('');
    const [trackcode, setTrackcode] = useState('');
    const [companyName, setCompanyName] = useState('');

    useEffect(() => {
        getProfile();
        setUploadedImg(Logo)
    }, [])

    const getFile = async (e) => {
        let file = e.target.files[0]
        if (file?.size < 5242880) {
            let excelData = await getBase64(file);
            let imgSrc = URL.createObjectURL(file);
            setUploadedFile(excelData)
            setUploadedImg(imgSrc)
        } else {
            sendNotify("error", "File is too big!, Upload below 5MB file.")
        }

    }
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const updateTicket = () => {
        const updateBody = {
            company_name: companyName,
            company_key: trackcode,
            files: uploadedFile
        }
        authRep.updateProfile(updateBody).then(response => {
            if (response) {
                if (response.error) {
                    sendNotify('error', response.error?.response?.message);
                } else {
                    sendNotify('success', response?.message);
                    getProfile()
                }
            }
        });

    }
    const getProfile = () => {
        authRep.getprofile().then(response => {
            if (response) {
                if (response.error) {
                    sendNotify('error', response.error?.response?.message);
                } else {
                    setTrackcode(response.data[0].company_key)
                    setCompanyName(response.data[0].company_name)
                    setUploaded(response.data[0].image)
                }
            }
        });

    }
    return (
        <section className='mt-md-3'>
            <div className='container-fluid px-0'>
                <div className="page-header">
                    <h1>Settings</h1>
                </div>
                <div className="row gy-4">
                    <div className="col-md-4">
                        <div className="chart-box">
                            <h4>Logo change:</h4>
                            <div className='mt-3 text-center'>
                                <Image
                                    className='custom-logo'
                                    src={`${uploaded}`}
                                    fallback={NoImg}
                                />
                                <input type="file" className='d-none' id='file' onChange={getFile} accept='image/png, image/jpg, image/jpeg' />
                                <label htmlFor="file" className='secondary-btn upload-btn mx-auto mt-4'><i className="far fa-cloud-upload-alt"></i> Update new</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="chart-box">
                            <h4>Company Name</h4>
                            <input type="text" className='box-input mt-3 w-100' value={companyName} onChange={(e) => setCompanyName(e.target.value)} placeholder='Enter prefix text' />
                            <h4 className='mt-3'>CompanyID prefix:</h4>
                            <input type="text" className='box-input mt-3 w-100' value={trackcode} onChange={(e) => setTrackcode(e.target.value)} placeholder='Enter prefix text' />
                            <p className='mt-4 mb-0'><span>*Note:</span> The above text was added to the prefix of the company's unique ID.</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                    {/* <button className='secondary-btn me-2' onClick={() => updateTicket()}><i className="far fa-undo-alt"></i> Reset <span className='d-none d-md-inline'>&nbsp;default</span></button> */}
                    <button className='primary-btn ms-2' onClick={() => updateTicket()}><i className="far fa-save"></i> Save <span className='d-none d-md-inline'>&nbsp;changes</span></button>
                </div>
            </div>
        </section>
    )
}
