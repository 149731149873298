import Repository, { apiUrl } from './index';

class TicketRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async getTickets() {
        let url = `${apiUrl}/tickets/getticktes`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async createticket(payload) {
        let url = `${apiUrl}/tickets/create`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

    async deleteTicketById(payload) {
        let url = `${apiUrl}/tickets/delete_ticket`;
        const reponse = await Repository.put(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async bulkAcknowledgeTicket(payload) {
        let url = `${apiUrl}/tickets/bulkupdateticktes`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async ticketExtraction(payload) {
        let url = `${apiUrl}/tickets/extraction`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

}

export default new TicketRepository();