import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Logo from '../../assets/img/logo.png'

import { NavLink } from "react-router-dom";

import routes from '../../helper/routes';

import { sidebarToggle } from '../../store/sidebar/sidebarSlice';

import Footer from '../base/Footer'

export default function Sidebar() {
  const dispatch = useDispatch()
  const [dropdown, setDropdown] = useState(false)

  const sidebarStatus = useSelector((state) => state.sidebar.sidebarStatus)

  return (
    <div className={`sidebar ${sidebarStatus ? 'sidebar-collapse' : ''}`}>
      <img src={Logo} className='logo' alt="NorthLark" />
      <ul>
        {routes.filter(r => r.showInMenu).map(route => {
          return (
            <li key={route.path}>
              <NavLink to={route.path}>
                <i className={`far ${route.icon}`}></i>
                <span>{route.name}</span>
              </NavLink>
            </li>
          )
        })}
      </ul>
      <button onClick={() => dispatch(sidebarToggle())} className={`toggle-btn ${sidebarStatus ? 'rotate' : ''}`}>
        <i className="fad fa-chevron-double-left"></i>
      </button>
      <Footer />
    </div>
  )
}
