import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'

import Logo from '../../assets/img/logo-with-text.png'

// import { checkLogged } from '../../store/auth/authSlice'
import authRep from '../../repository/authRep';
import { sendNotify } from '../../helper/notification';


export default function Register() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [togglePass, setTogglePass] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState()

  const signup = () => {
    let payload = {};
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (email && filter.test(email) && password) {
      payload['email'] = email
      payload['password'] = password
      payload['name'] = name
      payload['role'] = "client"
      payload["phone"] = phone
      authRep.signup(payload).then(response => {
        if (response) {
          if (response.error) {
            sendNotify('error', response.error?.response?.data?.message)
          } else {
            sendNotify('success', 'SignUp successfully, Please Login')
            navigate("/");
          }
        }
      })
        .catch(error => ({ error: JSON.stringify(error) }));
    } else {
      if (email == '') {
        sendNotify('error', 'Give your email')
      } else {
        if (!filter.test(email)) {
          sendNotify('error', 'Please provide a valid email')
        }
      }
      if (name == '') {
        sendNotify('error', 'Give your email')
      } else {
        sendNotify('error', 'Please provide a valid email')
      }

      if (password == '') {
        sendNotify('error', 'Give your password')
      }
    }
  }

  return (
    <div className='auth-form'>
      <img src={Logo} className='logo' alt="NL - Sendinel" />
      <h2>Register</h2>
      {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
      <div className="input-box">
        <label htmlFor="email">Username</label>
        <div className='input-field'>
          <input type="text" name='name' id='name' onChange={(e) => setName(e.target.value)} placeholder='Enter your username' />
        </div>
      </div>
      <div className="input-box">
        <label htmlFor="email">Phone</label>
        <div className='input-field'>
          <input type="phone" name='number' id='number' onChange={(e) => setPhone(e.target.value)} placeholder='Enter your phone' />
        </div>
      </div>
      <div className="input-box">
        <label htmlFor="email">Email</label>
        <div className='input-field'>
          <input type="email" name='email' id='email' onChange={(e) => setEmail(e.target.value)} placeholder='Enter your email address' />
        </div>
      </div>
      <div className="input-box">
        <label htmlFor="password">Password</label>
        <div className='input-field'>
          <input type={togglePass ? 'text' : 'password'} name='password' id='password'
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Enter your password' />
          <button className='input-after color-primary' onClick={() => setTogglePass(!togglePass)}>
            <i className={`far ${togglePass ? 'fa-eye-slash' : 'fa-eye'}`}></i>
          </button>
        </div>
      </div>
      <div className="input-box">
        <label htmlFor="password">Confirm Password</label>
        <input type='password' name='password' id='password' placeholder='Enter your password' />
      </div>
      <button className='primary-btn mx-auto' onClick={signup}>Register <i className="far fa-user-plus ms-2 me-0"></i></button>
      <p className='auth-footer'>Already have an account <Link to={'/login'}>login here.</Link></p>
    </div>
  )
}
