import React, { useEffect, useState } from 'react'
import { Select } from 'antd';
import { useParams } from "react-router-dom";

import jobRep from '../repository/jobRep';
import authRep from '../repository/authRep';
import { sendNotify } from '../helper/notification';
import moment from 'moment';
import { useNavigate } from "react-router-dom";


export default function JobStatus() {
  let { id } = useParams();
  const navigate = useNavigate()
  const [risklevel, setRiskLevel] = useState()
  const [jobDetails, setJobDetails] = useState({})
  const [status, setStatus] = useState(null)
  const [analyst, setAnalyst] = useState('')
  const [analystList, setAnalystList] = useState()

  useEffect(() => {
    getclientDetails();
    getAnalyst();
  }, [])

  let data = localStorage.getItem('session')
  let session = JSON.parse(data)
  let assignedBy = session.id

  const getclientDetails = () => {
    jobRep.getJobsById(id).then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.message)
        } else {
          setJobDetails(response?.data[0])
          if (response?.data[0]?.status) {
            setStatus(response?.data[0].status)
          }
        }
      }
    })
      .catch(error => (sendNotify('error', JSON.stringify(error))));
  }
  const getAnalyst = () => {
    authRep.analyst().then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.message)
        } else {
          setAnalystList(response?.data)
        }
      }
    })
      .catch(error => (sendNotify('error', JSON.stringify(error))));
  }
  const assignJob = () => {
    var payload = {
      "assignedBy": assignedBy,
      "status": status ? status : jobDetails.status,
      "recentChange": assignedBy,
      "assinedTo": analyst ? analyst : jobDetails.assinedTo,
      "id": id,
      "risk_level": risklevel ? risklevel : jobDetails.risk_level
    }
    jobRep.assignJobs(payload).then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.message)
        } else {
          sendNotify('success', response?.message)
          getclientDetails();
          navigate('/app/jobs')
        }
      }
    })
      .catch(error => (sendNotify('error', JSON.stringify(error))));
  }

  return (
    <section className='mt-md-3'>
      <div className="page-header">
        <h1><span>Job&nbsp;/&nbsp;</span>{id}</h1>
      </div>
      <div className='chart-box'>
        <div className="gird-view">
          <div className='job-cols'>
            <label>Job Status</label>
            <p>{jobDetails?.state ? jobDetails.state : "-"}</p>
          </div>
          <div className='job-cols'>
            <label>Job ID</label>
            <p>{jobDetails?.ticketData?.client_id ? jobDetails?.ticketData?.client_id : "-"}</p>
          </div>
          <div className='job-cols'>
            <label>Job Assignee</label>
            <p>{jobDetails?.assinedTo ? jobDetails.assinedTo : "-"}</p>
          </div>
          <div className='job-cols'>
            <label>Job Created Date</label>
            <p>{jobDetails?.createdAt ? moment(jobDetails?.createdAt).format('lll') : "-"}</p>
          </div>
          <div className='job-cols'>
            <label>Client Contact</label>
            <p>{jobDetails?.ticketData?.point_of_contact ? jobDetails?.ticketData?.point_of_contact : "-"}</p>
          </div>
          <div className='job-cols'>
            <label>Client</label>
            <p>{jobDetails?.ticketData?.client_name ? jobDetails?.ticketData?.client_name : "-"}</p>
          </div>
          <div className='job-cols'>
            <label>Client Category</label>
            <p>{jobDetails?.ticketData?.client_category ? jobDetails?.ticketData?.client_category : "-"}</p>
          </div>
          {jobDetails?.risk_level ?
            <div className='job-cols'>
              <label>Priority</label>
              <p>{jobDetails?.risk_level}</p>
            </div> : null}
        </div>
        <hr />
        <div className="gird-view">
          {jobDetails?.status === 'Create' ?
            <div className='job-cols'>
              <label className='mb-2'>Select Action</label>
              <Select
                className='custom-select'
                style={{ width: 250 }}
                onChange={(value) => setStatus(value)}
                placeholder="Switch Job Status"
                options={[{
                  value: 'Close',
                  label: 'Close',
                },
                {
                  value: 'Assign',
                  label: 'Assign',
                },
                ]}
              />
            </div> :
            <div className='job-cols'>
              <label className='mb-2'>Select Action</label>
              <Select
                className='custom-select'
                style={{ width: 250 }}
                onChange={(value) => setStatus(value)}
                placeholder="Switch Job Status"
                options={[
                  {
                    value: 'Approve',
                    label: 'Approve',
                  },
                  {
                    value: 'Reassign',
                    label: 'Reassign',
                  },
                  {
                    value: 'OnHold',
                    label: 'OnHold',
                  },
                  {
                    value: 'Reject',
                    label: 'Reject',
                  },
                  {
                    value: 'Close',
                    label: 'Close',
                  },
                ]}
              />
            </div>
          }
          {jobDetails?.status === 'Create' ?
            <div className='job-cols'>
              <label className='mb-2'>Analyst</label>
              <Select
                className='custom-select'
                style={{ width: 250 }}
                onChange={(value) => setAnalyst(value)}
                placeholder="Switch Job Status"
                options={analystList}
              />
            </div> :
            <>
              {status === "Reassign" && jobDetails?.state === 'Job Assigned' ?
                <div className='job-cols'>
                  <label className='mb-2'>Analyst</label>
                  <Select
                    className='custom-select'
                    style={{ width: 250 }}
                    onChange={(value) => setAnalyst(value)}
                    placeholder="Switch Job Status"
                    options={analystList}
                  />
                </div>
                : null}
            </>
          }

          {jobDetails?.risk_level ? <div className='job-cols'></div> :
            <div className='job-cols'>
              <label className='mb-2'>Risk Level</label>
              <Select
                className='custom-select'
                style={{ width: 250 }}
                onChange={(value) => setRiskLevel(value)}
                placeholder="Switch Job Status"
                options={[
                  {
                    value: 'Low',
                    label: 'Low',
                  },
                  {
                    value: 'Medium',
                    label: 'Medium',
                  },
                  {
                    value: 'High',
                    label: 'High',
                  },
                ]}
              />
            </div>}
          <div className='job-cos'>
          </div>
        </div>
        {/* <div className='job-cols my-3'>
          <label className='mb-2'>Comment Type</label>
          <div className="d-flex">
            <button className={`select-btn ${selected == 1 ? 'selected' : ''}`} onClick={() => setSelected(1)}><i class="fal fa-check-circle"></i> Internal</button>
            <button className={`select-btn ${selected == 2 ? 'selected' : ''}`} onClick={() => setSelected(2)}><i class="fal fa-check-circle"></i> External</button>
          </div>
        </div> */}
        {jobDetails?.status === 'Create' ? null :
          <div className='job-cols'>
            <label className='mb-2'>Comment</label>
            <textarea name="" id="" cols="50" rows="5" className='custom-input'></textarea>
          </div>}
        <div className='d-flex justify-content-center'>
          <button className='secondary-btn me-2'>Clear</button>
          <button className='primary-btn ms-2' onClick={() => assignJob()}>Submit</button>
        </div>
      </div>
    </section>
  )
}
