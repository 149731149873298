import Repository, { apiUrl } from './index';

class ClientRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async getClients() {
        let url = `${apiUrl}/clients/getclient`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async getClientsById(id) {
        let url = `${apiUrl}/clients/getclient?id=${id}`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async downloadFromS3(data) {
        let url = `${apiUrl}/clients/downloadclients?id=${data.id}&doc_name=${data.doc_name}&name=${data.name}`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async downloadSelected(payload) {
        let url = `${apiUrl}/clients/downloadselected`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async downloadLatest(payload) {
        let url = `${apiUrl}/clients/downloadlatest`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async uploadClient(payload) {
        let url = `${apiUrl}/clients/uploadclients`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async uploadClientdocuments(payload) {
        let url = `${apiUrl}/clients/uploadocuments`;
        console.log(payload, "fskvjnkj")
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async deleteClientdocuments(payload) {
        let url = `${apiUrl}/clients/deletedocuments`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                console.log(response, "payload")
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }


}

export default new ClientRepository();