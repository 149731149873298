import React from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Sidebar from './Sidebar'
import Navbar from './Navbar'
import Footer from './Footer'

export default function Index() {
  const sidebarStatus = useSelector((state) => state.sidebar.sidebarStatus)

  return (
    <div>
      <div className='base-layout'>
        <Sidebar />
        <div className={`main ${sidebarStatus ? 'full-width' : ''}`}>
          <Navbar />
          <div className='page-content'>
            <Outlet />
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </div>
  )
}
