import React, { useEffect, useState } from 'react'
import { Table, Tag, DatePicker } from 'antd';

import { Link } from 'react-router-dom';
import moment from 'moment';

import ClientRep from '../../repository/clientRep';
import { sendNotify } from '../../helper/notification';
import clientRep from '../../repository/clientRep';

const { RangePicker } = DatePicker;

export default function Clients() {
  const [tableData, setTableData] = useState([])

  let columns = [
    {
      title: 'Client',
      dataIndex: 'client',
    },
    {
      title: 'Client ID',
      dataIndex: 'clientId',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <>
          {status.map((s) => {
            let color = '';
            switch (s) {
              case 'Acknowledged':
                color = 'gold'
                break;
              case 'RFI':
                color = 'blue'
                break;
              case 'Completed':
                color = 'green'
                break;
              case 'OnHold':
                color = 'red'
                break;
            }
            return (
              <Tag color={color} key={s}>{s}</Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Partner',
      dataIndex: 'partner',
    },
    {
      title: 'POC',
      dataIndex: 'poc',
    },
    {
      title: 'Assigned to ',
      dataIndex: 'assignedTo',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, { clientId }) => (
        <Link to={`/app/client/${clientId}`} className='action-btn'><i className="far fa-eye"></i></Link>
      )
    }
  ];

  useEffect(() => {
    getjobs();
  }, [])

  const getjobs = () => {
    clientRep.getClients().then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.message)
        } else {
          if (response && response?.data.length > 0) {
            let obj = response?.data.map((data, i) => {
              return {
                client: data.ticketData.client_name,
                clientId: data.ticketData.client_id,
                status: [data.ticketData.status],
                partner: data.ticketData.partner,
                poc: data.ticketData.point_of_contact,
                assignedTo: data.assigned ? data.assigned : "Processcor",
                actions: (
                  <div className="d-flex" >
                    <Link to={`/app/job/${data.client_id}`} className='action-btn'><i className="far fa-eye"></i></Link>
                  </div>
                ),
              }
            })
            setTableData(obj)
          }
        }
      }
    })
      .catch(error => (sendNotify('error', JSON.stringify(error))));
  }

  return (
    <section className='mt-md-3'>
      <div className="page-header">
        <h1>Clients List</h1>
      </div>
      <div className="page-header flex-md-row flex-column">
        {/* <div className="custom-search my-1">
          <input type="text" placeholder='Search by client name/client ID' />
          <button><i className="far fa-search"></i></button>
        </div>
        <div className='d-flex mb-3'>
          <RangePicker className='custom-datepicker' />
          <button className='primary-btn ms-2'><i className="far fa-file-export me-0 me-md-1"></i> <span className='d-none d-md-inline'>Extract</span></button>
        </div> */}
      </div>

      <Table columns={columns} dataSource={tableData} className='custom-table' loading={false} />
    </section>
  )
}
