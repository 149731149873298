import React from 'react'
import { Link } from 'react-router-dom';
import { sendNotify } from '../../helper/notification'
import { Modal } from 'antd';
import { useNavigate } from "react-router-dom";


import Logo from '../../assets/img/logo-with-text.png'

const { confirm } = Modal;

export default function Navbar() {
  const navigate = useNavigate()

  let data = localStorage.getItem('session')
  let session = JSON.parse(data)

  const logout = () => {
    localStorage.clear('nl-auth');
    navigate('/')
    window.location.href = '/';
  }

  return (
    <nav className='navbar'>
      {/* <img src={Logo} className='logo' alt="NorthLark" /> */}
      {/* <h2>North<span>Lark</span> Sentinel</h2> */}
      {/* <div className="nav-items">

    const confirmLogout = () => {
        confirm({
          title: <h5>Confirm Logout</h5>,
          content: <p>Are you sure you want to log out?</p>,
          className: 'custom-confirm',
          centered: true,
          okText: 'Logout',
          onOk() {
            logout();
          },
          onCancel() {
            // console.log('Cancel');
          },
        });
      };
    
      const logout = () => {
        sendNotify('success', "Logout successfully!")
        
      }

  return (
    <nav className='navbar'>
        {/* <img src={Logo} className='logo' alt="NorthLark" /> */}
      <h2>North<span>Lark</span> Sentinel</h2>
      {/* <div className="nav-items">
            <Link to={'/'}>
                <span className='nav-link'><i className="far fa-cloud-upload"></i> <b>Upload Doc's</b></span>
            </Link>
            <Link to={'/track'}>
                <button className='nav-btn'><i className="far fa-location"></i> <b>Track Submitted</b></button>
            </Link>
        </div> */}
      <div className="profile">
        <img src="https://www.w3schools.com/w3images/avatar3.png" alt="" />
        <div className='d-flex flex-column justify-content-center ms-3 me-3'>
          <h6>{session && session.name}</h6>
          <p>{session && session.role}</p>
        </div>
        <i style={{ cursor: 'pointer' }} onClick={() => logout()} className="align-self-center fas fa-power-off ms-3 me-3"></i>
      </div>
    </nav>
  )
}
